import { format } from "date-fns";

export const formatAccountNum = (value) => {
  return value.replace(/(\d{6})(\d{6})/, "$1-$2");
};

export const formatter = new Intl.NumberFormat();
export function formatMonth(date) {
  var month = date.getMonth() + 1;
  return month < 10 ? "0" + month : "" + month; // ('' + month) for string result
}

export function _arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return binary;
}

export const formatDateToLocal = (date, cycle = false) => {
  const dueDate = new Date(date);
  const dtDateOnly = new Date(
    dueDate.valueOf() + dueDate.getTimezoneOffset() * 60 * 1000
  );
  if (cycle) {
    return format(new Date(dtDateOnly || Date.now()), "MMM dd").toUpperCase();
  } else {
    return format(
      new Date(dtDateOnly || Date.now()),
      "dd MMM yyyy"
    ).toUpperCase();
  }
};
