/**
 * @param  {"application"} {name
 * @param  {defaultState} initialState
 * @param  {{login:(state} reducers
 */
import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  currentPage: "",
  loading: {
    visible: false,
    message: "",
  },
};

let applicationSlice = createSlice({
  name: "application",
  initialState: defaultState,
  reducers: {
    /** @param {{payload: Boolean}} action */
    setLoading: (state, action) => {
      state.loading.visible = action.payload;
      if (!action.payload) state.loading.message = "";
    },
    /** @param {{payload: String}} action */
    setLoadingMessage: (state, action) => {
      state.loading.message = action.payload;
    },
    /** @param {{payload: String}} action */
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});

/** @param {{application: defaultState}} state */
export const isLoaderVisible = ({ application: state }) =>
  state.loading.visible;

/** @param {{application: defaultState}} state */
export const getLoaderMessage = ({ application: state }) =>
  state.loading.message;
/** @param {{application: defaultState}} state */
export const getCurrentPage = ({ application: state }) => state.currentPage;

export const { setLoading, setLoadingMessage, setCurrentPage } =
  applicationSlice.actions;

export default applicationSlice.reducer;
