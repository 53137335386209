import React, { useState } from "react";

export default function Applications() {
  const [personalInfo, setPersonalInfo] = useState(false);
  const [parentInfo, setParentInfo] = useState(false);
  const [guarantorInfo, setGuarantorInfo] = useState(false);
  const [studentInfo, setStudentInfo] = useState(false);
  const [familyInfo, setFamilyInfo] = useState(false);
  const [nextOfKinInfo, setNextOfKinInfo] = useState(false);
  return (
    <>
      <ul className="list-group">
        <li className="list-group-item">
          <div className="row">
            <span className="col-lg-11">James Harden - Regular Single</span>
            <span className="col-lg-1">
              <p
                data-toggle="modal"
                data-target="#exampleModal"
                style={{ backgroundColor: "#0075c1" }}
                className="btn btn-circle"
              >
                <i className="fas fa-eye" style={{ color: "#fff" }}></i>
              </p>
            </span>
          </div>
        </li>
      </ul>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title font-weight-bold"
                id="exampleModalLabel"
              >
                James Harden - Regular Single
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="modal-body container"
              style={{ overflowY: "scroll", height: 500 }}
            >
              <div className="row">
                <div className="col-md-12">
                  <h5>
                    Personal Information{" "}
                    <input
                      className="form-check-input ml-3"
                      type="checkbox"
                      onChange={(e) => setPersonalInfo(e.target.checked)}
                      value={personalInfo}
                    />
                  </h5>
                </div>
                <div className="col-md-6">
                  <b>Full Name:</b> James Harden
                  <b>Date Of Birth:</b> JAN-23-1982
                </div>
                <div className="col-md-6">
                  <b>Cell 1.:</b> (876) 343-2384 <br />
                  <b>Cell 2.</b> N/A
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <b>Email Address: </b>jharden@gmail.com
                </div>
                <div className="col-md-6">
                  <b>TRN:</b> 122-220-388
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <b>Faculty:</b>{" "}
                  <span className="text-truncate d-flex">Medical Science</span>
                  <b>Program Pursing:</b> Phlebotomy
                </div>
                <div className="col-md-6">
                  <b>Current Year:</b> 2020 <br />
                  <b>Graduation Year.</b> 2026
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  <h5>
                    Parent/Guardian Information{" "}
                    <input
                      className="form-check-input ml-3"
                      type="checkbox"
                      onChange={(e) => setParentInfo(e.target.checked)}
                      value={parentInfo}
                    />
                  </h5>{" "}
                </div>
                <div className="col-md-6">
                  <b>Full Name:</b> Paul Harden <b>TRN:</b> 129-349-495
                </div>
                <div className="col-md-6">
                  <b>Cell 1.:</b> (876) 343-2384 <br />
                  <b>Cell 2.</b> N/A
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <b>Email Address: </b>sharden@gmail.com
                </div>
                <div className="col-md-6">
                  <b>TRN:</b> 122-220-388
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  <h5>
                    Guarantor Information{" "}
                    <input
                      className="form-check-input ml-3"
                      type="checkbox"
                      onChange={(e) => setGuarantorInfo(e.target.checked)}
                      checked={guarantorInfo}
                    />
                  </h5>{" "}
                </div>
                <div className="col-md-6">
                  <b>Full Name:</b> Stephen Smith <b>TRN:</b> 119-249-495
                </div>
                <div className="col-md-6">
                  <b>Cell 1.:</b> (876) 343-2384 <br />
                  <b>Cell 2.</b> N/A
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  <h5>
                    Student Employment/Income Information{" "}
                    <input
                      className="form-check-input ml-3"
                      type="checkbox"
                      checked={studentInfo}
                      onChange={(e) => setStudentInfo(e.target.checked)}
                    />
                  </h5>{" "}
                </div>
                <div className="col-md-6">
                  <b>Employer:</b> Digicel <b>Address:</b> 14 Ocean Blvd,
                  Kingston
                </div>
                <div className="col-md-6">
                  <b>Position Held:</b> Data Analyst <br />
                  <b>Manager's Name</b> Scott Adams
                </div>
                <div className="col-md-6">
                  <b>Phone:</b> (876) 412-3937 <br />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  <h5>
                    Family and Reference Information{" "}
                    <input
                      className="form-check-input ml-3"
                      type="checkbox"
                      checked={familyInfo}
                      onChange={(e) => setFamilyInfo(e.target.checked)}
                    />
                  </h5>{" "}
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="col-md-12">
                      <p className="font-weight-bold">
                        <u>Family</u>
                      </p>
                      <b>Name:</b> Thomas Wayne <b>Affiliation:</b> Brother
                    </div>
                    <div className="col-md-12">
                      <b>Address</b> 3 Example Way <br />
                      <b>Phone:</b> (876) 412-3937 <br />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="col-md-12">
                      <p className="font-weight-bold">
                        <u>Friend/Professional</u>
                      </p>
                      <b>Name:</b> Thomas Wayne <b>Affiliation:</b> Co-Worker
                    </div>
                    <div className="col-md-12">
                      <b>Address</b> 3 Example Way <br />
                      <b>Phone:</b> (876) 412-3937 <br />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  <h5>
                    Next of Kin/Emergency Information{" "}
                    <input
                      className="form-check-input ml-3"
                      type="checkbox"
                      checked={nextOfKinInfo}
                      onChange={(e) => setNextOfKinInfo(e.target.checked)}
                    />
                  </h5>{" "}
                </div>
                <div className="col-md-6">
                  <b>Name:</b> Timmy Harden <b>Address:</b> 14 Ocean Blvd,{" "}
                </div>
                <div className="col-md-6">
                  <b>Cell 1.:</b> (876) 343-2384 <br />
                  <b>Cell 2.</b> N/A
                </div>
                <div className="col-md-6">
                  <b>Releationship:</b> Mother <br />
                  <b>Telephone</b> (876) 482-3434
                </div>
                <div className="col-md-6">
                  <b>Physician:</b> Dr. K. Wong
                  <br />
                </div>
                <div className="col-md-6">
                  <b>Alergies:</b> N/A <br />
                </div>
                <div className="col-md-6">
                  <b>Notable Symptoms</b> N/A
                  <br />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                data-toggle="modal"
                className="custom-button-alt"
                data-target="#messageModal"
                data-dismiss="modal"
                disabled={
                  !(
                    familyInfo ||
                    personalInfo ||
                    guarantorInfo ||
                    nextOfKinInfo ||
                    studentInfo
                  )
                }
              >
                {console.log(
                  "Is: ",
                  familyInfo &&
                    guarantorInfo &&
                    nextOfKinInfo &&
                    studentInfo &&
                    personalInfo
                )}
                Reject With Message
              </button>
              <button
                disabled={
                  !(
                    familyInfo &&
                    guarantorInfo &&
                    nextOfKinInfo &&
                    studentInfo &&
                    personalInfo
                  )
                }
                type="button"
                className="custom-button"
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="messageModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">Rejection Message</div>

            <div className="modal-body container">
              <div className="alert alert-success">
                Message Successfully Sent!
              </div>
              <label htmlFor="">
                Message <span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                className="form-control"
                style={{ height: "50%" }}
              ></textarea>
            </div>
            <div className="modal-footer">
              <button className="custom-button" data-dismiss="modal">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
