import React, { useState } from "react";
import { useEffect } from "react";
import AdminNetworkLayer from "../../../helpers/admin";
import PropertyNetworkLayer from "../../../helpers/properties";
import $ from "jquery";
import DataTable from "datatables.net";
import "./../index.scss";
import Modal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import { Col, Jumbotron, Row, Button } from "reactstrap";
import { formatDateToLocal, _arrayBufferToBase64 } from "../../functions";
import { setLoading, setLoadingMessage } from "../../../globals/application";
import { RECEIPT, RECEIPT_COLUMNS, tableIcons } from "../../constants";

import { setReceipt } from "../../../globals/student";
// import MaterialTable from "material-table";

export default function Receipts() {
  const [listOfReceipts, setListOfReceipts] = useState([]);

  const [file, setCurrentFile] = useState("");

  const dispatch = useDispatch();

  /**
   *
   * @param {Number} receipt_id
   */
  const setReceiptData = async (receipt_id) => {
    if (file !== "") setCurrentFile("");
    const params = new URLSearchParams();
    params.append("file_id", receipt_id);
    params.append("file_type", RECEIPT);
    let receiptFile = await AdminNetworkLayer.getFile(params, "token");
    setCurrentFile(receiptFile?.receipt_photo);
  };

  const reload = async () => {
    dispatch(setLoading(true));
    dispatch(
      setLoadingMessage(
        "Please wait while we retrieve all the receipts uploaded"
      )
    );
    let receipts = await AdminNetworkLayer.getAllReceipts();
    dispatch(setReceipt(receipts));
    setListOfReceipts(receipts);
    dispatch(setLoading(false));
  };

  const updateStatus = async (issue_id, status) => {
    const params = new URLSearchParams();
    params.append("issue_id", issue_id);
    params.append("status", status);
    let result = await AdminNetworkLayer.updateStatus(params);
  };

  const tableRef = React.createRef();
  useEffect(async () => {
    dispatch(setLoading(true));
    dispatch(
      setLoadingMessage(
        "Please wait while we retrieve all the receipts uploaded"
      )
    );
    let receipts = await AdminNetworkLayer.getAllReceipts();

    setListOfReceipts(receipts);
    // setImages(temp);

    $(document).ready(function () {
      $("#datatable").DataTable();
    });
    dispatch(setLoading(false));
  }, []);
  console.log("File", file);
  return (
    <>
      <Modal
        modalTitle="Receipt Photo"
        secondaryLabel="Close"
        modalContent={
          <>
            <Row className="d-flex justify-content-center">
              {file !== "" ? (
                <img
                  alt="file-upload"
                  className="w-100 h-100"
                  src={_arrayBufferToBase64(file?.data)}
                />
              ) : (
                <div
                  className="spinner-border"
                  style={{ width: "3rem", height: "3rem" }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </Row>
          </>
        }
      />
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns search-results">
            <table
              className="table table-bordered table-hover"
              id="datatable"
              width="100%"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th>Receipt ID</th>
                  <th>Bill Type</th>
                  <th>Submission Date</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Preview</th>
                </tr>
              </thead>
              <tbody>
                {listOfReceipts.map((receipt, index) => {
                  return (
                    <tr className="student" key={index}>
                      <td>{receipt.receipt_id}</td>
                      <td className="w-50 font-weight-bold">
                        {receipt.receipt_type.split("-")[0].toUpperCase() +
                          " " +
                          receipt.receipt_type.split("-")[1].toUpperCase()}
                      </td>
                      <td>
                        {formatDateToLocal(receipt.receipt_submission_date)}
                      </td>
                      <td>{receipt.student.first_name}</td>
                      <td>{receipt.student.last_name}</td>
                      <td>
                        <button
                          data-toggle="modal"
                          data-target="#exampleModal"
                          onClick={() => setReceiptData(receipt.receipt_id)}
                          className="btn btn-primary"
                        >
                          Open
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
