import { getYear } from "date-fns";
import { object, string } from "yup";
import { formatDateToLocal, formatMonth } from "./functions";
import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";

import ChevronLeft from "@material-ui/icons/ChevronLeft";
import DateRange from "@material-ui/icons/DateRange";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";

import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

//* Icon definition for table
export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DateRange: forwardRef((props, ref) => <DateRange {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <div />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const jpsDueDate = `${getYear(new Date())}-${formatMonth(
  new Date()
)}-30`;
export const nwcDueDate = `${getYear(new Date())}-${formatMonth(
  new Date()
)}-26`;

export const internetDueDateList = {
  "Property A": {
    dueDate: `${getYear(new Date())}-${formatMonth(new Date())}-09`,
  },
  "Property B": {
    dueDate: `${getYear(new Date())}-${formatMonth(new Date())}-10`,
  },
  "Property C": {
    dueDate: `${getYear(new Date())}-${formatMonth(new Date())}-11`,
  },
  "Property D": {
    dueDate: `${getYear(new Date())}-${formatMonth(new Date())}-12`,
  },
  "Property E": {
    dueDate: `${getYear(new Date())}-${formatMonth(new Date())}-13`,
  },
  "Property F": {
    dueDate: `${getYear(new Date())}-${formatMonth(new Date())}-14`,
  },
  "Property G": {
    dueDate: `${getYear(new Date())}-${formatMonth(new Date())}-15`,
  },
  "Property H": {
    dueDate: `${getYear(new Date())}-${formatMonth(new Date())}-16`,
  },
  "Property I": {
    dueDate: `${getYear(new Date())}-${formatMonth(new Date())}-17`,
  },
  "Property H Flat": {
    dueDate: `${getYear(new Date())}-${formatMonth(new Date())}-18`,
  },
  "Property C Flat": {
    dueDate: `${getYear(new Date())}-${formatMonth(new Date())}-19`,
  },

  "Property G Flat-1": {
    dueDate: `${getYear(new Date())}-${formatMonth(new Date())}-20`,
  },
  "Property GFlat-2": {
    dueDate: `${getYear(new Date())}-${formatMonth(new Date())}-21`,
  },
  "Property GFlat-3": {
    dueDate: `${getYear(new Date())}-${formatMonth(new Date())}-22`,
  },
};

export const initalValues = {
  billType: "JPS",
  balanceDue: "",
  propertyId: "1",
  accountNumber: "",
  homeAddress: "",
  dueDate: "",
  accountName: "",
  meterNumber: "",
  cycleFrom: "",
  cycleTo: "",
  billFile: "",
};

export const uploadSchema = object().shape({
  billType: string().required("Please state the type of bill being uploaded"),
  propertyId: string().required("Please select the property"),
  balanceDue: string().required("The balance that is due is required"),
  accountNumber: string().required("Account Number is required"),
  homeAddress: string().required("Home Address is required"),
  dueDate: string().required("The Due date for the bill is required"),
  cycleFrom: string().required("The Billing cycle from period is required"),
  cycleTo: string().required(
    "The Billing cycle to period for the bill is required"
  ),
  accountName: string().required("The name on the Account is needed"),
  meterNumber: string(),
  billFile: string(),
});

export const internetUploadSchema = object().shape({
  billType: string().required("Please state the type of bill being uploaded"),
  propertyId: string().required("Please select the property"),
  balanceDue: string().required("The balance that is due is required"),
  accountNumber: string().required("Account Number is required"),
  homeAddress: string().required("Home Address is required"),
  dueDate: string().required("The Due date for the bill is required"),
});

export const LIST_OF_STATUS = ["Pending", "In Progress", "Done"];
export const ISSUE = "ISSUE";
export const RECEIPT = "RECEIPT";

export const COLUMNS = [
  { title: "Bill ID", field: "bill_id", editable: "never" },
  { title: "Bill Name", field: "bill_name", editable: "never" },
  { title: "Account Name", field: "account_name", editable: "never" },
  {
    title: "Account Number",
    field: "account_number",
    editable: "never",
  },
  {
    title: "Cycle From",
    field: "cycle_from",
    type: "date",
    width: "33.3%",
  },
  {
    title: "Cycle To",
    field: "cycle_to",
    type: "date",
    width: "33.3%",
  },
  {
    title: "Due Date",
    type: "date",
    field: "bill_dueDate",
    render: (rowData) => (
      <td value={rowData?.bill_dueDate}>
        {formatDateToLocal(rowData?.bill_dueDate)}
      </td>
    ),
  },
  {
    title: "Balance",
    field: "balance_due",
    type: "numeric",
  },
];

export const RECEIPT_COLUMNS = [
  {
    title: "Receipt ID",
    field: "receipt_id",
    editable: "never",
    filterPlaceholder: "Filter by Receipt ID",
  },
  {
    title: "Receipt Type",
    field: "receipt_type",
    editable: "never",
    filterPlaceholder: "Filter by Receipt Type",
    render: (rowData) => (
      <td className="text-uppercase font-weight-bold text-nowrap">
        {rowData?.receipt_type.split("-")[0]}{" "}
        {rowData?.receipt_type.split("-")[1]}
      </td>
    ),
  },
  {
    title: "Date Submitted",
    field: "receipt_submission_date",
    type: "date",
    filterPlaceholder: "Filter by Date",
    render: (rowData) => (
      <td value={rowData?.bill_dueDate}>
        {formatDateToLocal(rowData?.receipt_submission_date)}
      </td>
    ),
    editable: "never",
  },
  {
    title: "First Name",
    field: "student.first_name",
    editable: "never",
    filterPlaceholder: "Filter by First Name",
  },
  {
    title: "Last Name",
    field: "student.last_name",
    editable: "never",
    filterPlaceholder: "Filter by Last Name",
  },
];
