import React, { useState } from "react";
import { useEffect } from "react";
import AdminNetworkLayer from "../../../helpers/admin";

import $ from "jquery";
import DataTable from "datatables.net";
import "./../index.scss";
import Modal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import { Col, Jumbotron, Row, Button } from "reactstrap";

import { _arrayBufferToBase64 } from "../../functions";
import { setLoading, setLoadingMessage } from "../../../globals/application";
import { ISSUE, LIST_OF_STATUS } from "../../constants";
import { getIssues, setIssues } from "../../../globals/student";

export default function ReportedIssues() {
  const [currentIssue, setCurrentIssue] = useState({});
  const [listOfIssues, setListOfIssues] = useState([]);
  const [file, setCurrentFile] = useState("");

  const dispatch = useDispatch();

  const setIssueData = async (issue) => {
    if (file !== "") setCurrentFile("");
    setCurrentIssue(issue);
    const params = new URLSearchParams();
    params.append("file_id", issue?.issue_id);
    params.append("file_type", ISSUE);
    let issueFile = await AdminNetworkLayer.getFile(params, "token");
    setCurrentFile(issueFile?.issue_photo);
  };

  const reload = async () => {
    dispatch(setLoading(true));
    dispatch(
      setLoadingMessage("Please wait while we retrieve all the issues reported")
    );
    let issues = await AdminNetworkLayer.getAllIssues();
    console.log("Issues =>", issues);
    setListOfIssues(issues);
    dispatch(setIssues(issues));
    dispatch(setLoading(false));
  };

  const updateStatus = async (issue_id, status) => {
    const params = new URLSearchParams();
    params.append("issue_id", issue_id);
    params.append("status", status);
    let result = await AdminNetworkLayer.updateStatus(params);
  };

  useEffect(async () => {
    dispatch(setLoading(true));
    dispatch(
      setLoadingMessage("Please wait while we retrieve all the issues reported")
    );
    try {
      let issues = await AdminNetworkLayer.getAllIssues();
      console.log("Issues =>", issues);
      setListOfIssues(issues);
      dispatch(setLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    }

    $(document).ready(function () {
      $("#datatable").DataTable();
    });
    dispatch(setLoading(false));
  }, []);
  return (
    <>
      <Modal
        modalTitle="Student File"
        secondaryLabel="Close"
        modalContent={
          <>
            <Row>
              <Col xs={12} md={6} lg={6} sm={12}>
                <span className="font-weight-bold">Name</span>:{" "}
                {currentIssue?.student?.first_name}{" "}
                {currentIssue?.student?.last_name}
              </Col>
              <Col xs={12} md={6} lg={6} sm={12} className="text-right">
                <span className="font-weight-bold">Email</span>:{" "}
                <a href={`mailto:${currentIssue.email}`}>
                  {currentIssue?.student?.email}
                </a>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={6} sm={12}>
                <span className="font-weight-bold">Contact</span>:{" "}
                {currentIssue?.student?.contact}
              </Col>
              <Col xs={12} md={6} lg={6} sm={12} className="text-right">
                <span className="font-weight-bold">Room:</span>:{" "}
                {currentIssue?.student?.roomRoomCode}
              </Col>
            </Row>

            <br />
            <Row className="d-flex justify-content-center">
              {file !== "" ? (
                <img
                  alt="file-upload"
                  className="w-100 h-100"
                  src={_arrayBufferToBase64(file?.data)}
                />
              ) : (
                <div
                  className="spinner-border"
                  style={{ width: "3rem", height: "3rem" }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </Row>
          </>
        }
      />
      {listOfIssues?.length !== 0 ? (
        <div className="card shadow mb-4">
          <div className="card-body">
            <div className="table-responsive dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns search-results">
              <table
                className="table table-bordered table-hover"
                id="datatable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>Issues ID</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Student's Name</th>
                    <th>Preview</th>
                  </tr>
                </thead>
                <tbody>
                  {listOfIssues.map((issue, index) => {
                    return (
                      <tr className="student" key={issue.issue_id}>
                        <td>{issue.issue_id}</td>
                        <td className="w-50">{issue.issue_description}</td>
                        <td>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              updateStatus(issue.issue_id, e.target.value);
                              let oldArr = listOfIssues;
                              oldArr[index] = {
                                ...oldArr[index],
                                status: e.target.value,
                              };
                              setListOfIssues(oldArr);
                              console.log(oldArr);
                            }}
                          >
                            <option value={issue.status}>{issue.status}</option>
                            {LIST_OF_STATUS.filter(
                              (el) => el !== issue.status
                            ).map((st) => (
                              <option value={st}>{st}</option>
                            ))}
                          </select>
                        </td>
                        <td>
                          {issue.student.first_name} {issue.student.last_name}
                        </td>
                        <td>
                          <button
                            data-toggle="modal"
                            data-target="#exampleModal"
                            onClick={() => setIssueData(issue)}
                            className="btn btn-primary"
                          >
                            Open
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <Jumbotron>
          <h1 className="display-3">No Issues Available</h1>
          <p className="lead">
            Oops! Seems there was an issue getting the issues from the database.
            Press the reload button to try again.
          </p>
          <hr className="my-2" />
          <Button onClick={reload} className="custom-button">
            Reload
          </Button>
        </Jumbotron>
      )}
    </>
  );
}
