import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setCurrentPage } from "../../../globals/application";
import "../index.scss";

export default function Sidebar() {
  const dispatch = useDispatch();

  const navigateToComponent = (page) => dispatch(setCurrentPage(page));
  return (
    <ul
      className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
      id="accordionSidebar"
      style={{ backgroundColor: "#0075c1" }}
    >
      <Link
        className="sidebar-brand d-flex align-items-center justify-content-center"
        to="/"
      >
        <div className="sidebar-brand-icon rotate-n-15">
          <i className="fas fa-home"></i>
        </div>
        <div className="sidebar-brand-text mx-3">Zenith Admin Panel</div>
      </Link>

      <hr className="sidebar-divider my-0" />

      <li className="nav-item">
        <p
          className="nav-link"
          onClick={() => navigateToComponent("dashboard")}
        >
          <i className="fas fa-fw fa-tachometer-alt"></i>
          <span>Dashboard</span>
        </p>
      </li>

      <hr className="sidebar-divider" />

      <div className="sidebar-heading">Main Menu</div>

      <li className="nav-item">
        <p
          className="nav-link collapsed"
          onClick={() => navigateToComponent("dashboard")}
        >
          <i className="fas fa-fw fa-file"></i>
          <span>Student Documents</span>
        </p>
      </li>
      <li className="nav-item">
        <p
          className="nav-link collapsed"
          onClick={() => navigateToComponent("issues")}
        >
          <i className="fas fa-fw fa-tools"></i>
          <span>Reported Issues</span>
        </p>
      </li>

      {/* <li className="nav-item">
        <p
          className="nav-link collapsed"
          data-toggle="collapse"
          data-target="#collapseTwo"
          aria-expanded="true"
          aria-controls="collapseTwo"
          onClick={() => navigateToComponent("application")}
        >
          <i className="fas fa-fw fa-eye"></i>
          <span>View Applications</span>
        </p>
      </li> */}
      <li class="nav-item">
        <a
          class="nav-link collapsed"
          data-toggle="collapse"
          data-target="#collapsePages"
          aria-expanded="true"
          aria-controls="collapsePages"
        >
          <i class="fas fa-fw fa-folder"></i>
          <span>Utility Bills</span>
        </a>
        <div
          id="collapsePages"
          class="collapse"
          aria-labelledby="headingPages"
          data-parent="#accordionSidebar"
        >
          <div class="bg-white py-2 collapse-inner rounded">
            <h6 class="collapse-header">Utility Bills Menu:</h6>
            <a
              class="collapse-item"
              style={{ cursor: "pointer" }}
              onClick={() => navigateToComponent("upload")}
            >
              Create
            </a>
            <a
              class="collapse-item"
              style={{ cursor: "pointer" }}
              onClick={() => navigateToComponent("upload-edit")}
            >
              Edit
            </a>
            <a class="collapse-item" style={{ cursor: "pointer" }}>
              Delete
            </a>
            <div class="collapse-divider"></div>
          </div>
        </div>
      </li>

      <li className="nav-item">
        <p
          className="nav-link collapsed"
          onClick={() => navigateToComponent("receipts")}
          data-toggle="collapse"
          data-target="#collapseTwo"
          aria-expanded="true"
          aria-controls="collapseTwo"
        >
          <i className="fas fa-eye"></i>
          <span>View Receipts</span>
        </p>
      </li>

      <hr className="sidebar-divider d-none d-md-block" />

      <div className="text-center d-none d-md-inline">
        <button className="rounded-circle border-0" id="sidebarToggle"></button>
      </div>
    </ul>
  );
}
