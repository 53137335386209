import axios from "./../axios";
// import axios from "axios";
export default class AuthNetworkLayer {
  /**
   * @param  {Object} data
   * @param  {String} token
   */
  static async loginStudent(data, token) {
    const endpoint = "auth/login";

    let response = await axios.post(endpoint, data);

    return response.data;
  }

  /**
   * @param  {Object} data
   * @param  {String} token
   */
  static async loginAdmin(data, token) {
    const endpoint = "auth/admin-login";

    let response = await axios.post(endpoint, data);

    return response.data;
  }
}
