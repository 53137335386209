import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import AdminNetworkLayer from "../../../helpers/admin";
import { formatDateToLocal } from "../../functions";
import $ from "jquery";
import DataTable from "datatables.net";
import MaterialTable from "material-table";
import { ALLOWEDEDITABLEFIELDS, COLUMNS, tableIcons } from "../../constants";
import { toast } from "react-toastify";
import el from "date-fns/esm/locale/el/index.js";
import { Edit } from "@material-ui/icons";

export default function EditBill() {
  const [bills, setBillsList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  $(document).ready(function () {
    $("#datatable").DataTable();
  });
  useEffect(async () => {
    const results = await AdminNetworkLayer.getAllBills();
    if (results) {
      setBillsList(results);
    }
  }, []);

  const onCellEditApproved = (newValue, oldValue, rowData, columnDef) => {
    return new Promise(async (resolve, reject) => {
      //Create a temporary array from the state array
      const temp = bills;

      // Isolate the row by the bill id number
      let row = temp.find((el) => el.bill_id === rowData?.bill_id);

      //Change the value of the current row
      row[columnDef.field] = newValue;

      //Find the position of the exact row
      const replacedItem = temp
        .map((el) => el.bill_id)
        .indexOf(rowData?.bill_id);

      // Override that position with the new row data
      temp[replacedItem] = row;

      // Persist the change to the usestate and update database
      const params = new URLSearchParams();
      params.append("field_type", columnDef?.field);
      params.append("bill_id", rowData?.bill_id);
      params.append("new_data", newValue);
      const res = await AdminNetworkLayer.updateBill(params);
      toast.success(res);
      setBillsList(temp);
      setTimeout(resolve, 4000);
    });
  };

  return (
    <MaterialTable
      icons={tableIcons}
      columns={COLUMNS}
      cellEditable={{
        cellStyle: {},
        onCellEditApproved,
      }}
      data={bills}
      title="Utility Bills"
    />
  );
}
