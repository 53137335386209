import React from "react";
import { useSelector } from "react-redux";
import Spinner from "./Spinner";

import "./LoadSpinner.scss";
import {
  getLoaderMessage,
  isLoaderVisible,
} from "../../../globals/application";

/**
 * A custom css-based spinner which
 * uses divs as dots
 * supports custom messages pulled from redux state
 */
function LoadSpinner() {
  const active = useSelector(isLoaderVisible);
  const message = useSelector(getLoaderMessage);

  return (
    <div className={`load-spinner${active ? " active" : ""}`}>
      {active && <Spinner />}
      <p className="append-dots saving">
        {"Loading "}
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </p>
      {message && (
        <p className="loader-message">
          {`${message} `}
          <span className="append-dots saving">
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </span>
        </p>
      )}
    </div>
  );
}

export default LoadSpinner;
