import React, { useState } from "react";
import { useEffect } from "react";
import AdminNetworkLayer from "../../../helpers/admin";
import PropertyNetworkLayer from "../../../helpers/properties";
import $ from "jquery";
import DataTable from "datatables.net";
import "./../index.scss";
import Modal from "./Modal";
import { useDispatch } from "react-redux";
import {
  Col,
  Jumbotron,
  Row,
  Button,
  Input,
  Form,
  Label,
  FormGroup,
} from "reactstrap";
import { setProperties } from "../../../globals/student";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const mySwal = withReactContent(Swal);
export default function AdminDashbard() {
  const [currentStudent, setCurrentStudent] = useState({});
  const [listOfStudents, setListOfStudents] = useState([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const setStudentData = (student) => setCurrentStudent(student);
  const reload = async () => {
    let students = await AdminNetworkLayer.getStudents();
    let properties = await PropertyNetworkLayer.getProperties();
    setListOfStudents(students);
    dispatch(setProperties(properties));
  };

  const handleSubmit = async (email) => {
    const params = new URLSearchParams();
    params.append("email", email);
    params.append("subject", subject);
    params.append("body", message);

    let resp = await AdminNetworkLayer.sendEmail(params);

    if (resp.success) {
      let result = await mySwal.fire({
        icon: "success",
        title: "Email Sent",
        text: `${resp.message}`,
      });

      if (result.isConfirmed) {
        setMessage("");
        setSubject("");
      }
    } else {
      let result = await mySwal.fire({
        icon: "error",
        title: "Email Not Sent",
        text: `${resp.message}`,
      });

      if (result.isConfirmed) {
        setMessage("");
        setSubject("");
      }
    }
  };

  useEffect(async () => {
    let students = await AdminNetworkLayer.getStudents();
    let properties = await PropertyNetworkLayer.getProperties();
    setListOfStudents(students);
    dispatch(setProperties(properties));
    $(document).ready(function () {
      $("#datatable").DataTable();
    });
  }, []);
  return (
    <>
      <Modal
        modalTitle="Student File"
        modalContent={
          <>
            <Row>
              <Col xs={12} md={6} lg={6} sm={12}>
                <span className="font-weight-bold">Name</span>:{" "}
                {currentStudent.first_name} {currentStudent.last_name}
              </Col>
              <Col xs={12} md={6} lg={6} sm={12} className="text-right">
                <span className="font-weight-bold">Email</span>:{" "}
                <span className="link">{currentStudent.email}</span>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={6} sm={12}>
                <span className="font-weight-bold">Contact</span>:{" "}
                {currentStudent.contact}
              </Col>
              <Col xs={12} md={6} lg={6} sm={12} className="text-right">
                <span className="font-weight-bold">Room:</span>:{" "}
                {currentStudent.roomRoomCode}
              </Col>
            </Row>
            <Row>
              <p className="text-center">
                <button
                  className="btn btn-info mt-3"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  Email {currentStudent.first_name}
                </button>
              </p>

              <div className="collapse" id="collapseExample">
                <Form className="p-3">
                  <Label htmlFor="subject">
                    Subject <span className="text-danger">*</span>
                  </Label>
                  <Input
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    id="subject"
                    className="mb-3"
                    type="text"
                  />
                  <FormGroup>
                    <Label htmlFor="message">
                      Message <span className="text-danger">*</span>
                    </Label>
                    <Input
                      placeholder="Type something..."
                      style={{ height: 120 }}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      type="textarea"
                      className="mb-3"
                      id="message"
                    />
                    {message.length < 10 && (
                      <p className=" text-danger">
                        Message must be at least 10 characters
                      </p>
                    )}
                  </FormGroup>
                  <Button
                    onClick={() => handleSubmit(currentStudent.email)}
                    disabled={message.length < 10 || subject === ""}
                    className="custom-button ml-auto"
                  >
                    Send
                  </Button>
                </Form>
              </div>
            </Row>
          </>
        }
      />
      {listOfStudents.length !== 0 ? (
        <div className="card shadow mb-4">
          <div className="card-body">
            <div className="table-responsive dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns search-results">
              <table
                className="table table-bordered table-hover"
                id="datatable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>User ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Contact #</th>
                    <th>Room Code</th>
                  </tr>
                </thead>
                <tbody>
                  {listOfStudents.map((student) => {
                    return (
                      <tr
                        className="student"
                        data-toggle="modal"
                        data-target="#exampleModal"
                        onClick={() => setStudentData(student)}
                        key={student.user_id}
                      >
                        <td>{student.user_id}</td>
                        <td>{student.first_name}</td>
                        <td>{student.last_name}</td>
                        <td>{student.email}</td>
                        <td>{student.contact}</td>
                        <td>{student.roomRoomCode}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <Jumbotron>
          <h1 className="display-3">No Student Available</h1>
          <p className="lead">
            Oops! Seems there was an issue getting the students from the
            database. Press the reload button to try again.
          </p>
          <hr className="my-2" />
          <Button onClick={reload} className="custom-button">
            Reload
          </Button>
        </Jumbotron>
      )}
    </>
  );
}
