/**
 * @param  {"student"} {name
 * @param  {defaultState} initialState
 * @param  {{login:(state} reducers
 */
import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  issues: [],
  roomDetails: null,
  propertyDetails: null,
  rooms: [],
  properties: [],
  student: {},
  receipts: [],
  currentFile: "",
};

let studentSlice = createSlice({
  name: "student",
  initialState: defaultState,
  reducers: {
    setIssues: (state, action) => {
      state.issues = action.payload;
    },
    setCurrentFile: (state, action) => {
      state.currentFile = action.payload;
    },
    setReceipt: (state, action) => {
      state.receipts = action.payload;
    },
    setPropertyDetails: (state, action) => {
      state.propertyDetails = action.payload;
    },
    setRoomDetails: (state, action) => {
      state.roomDetails = action.payload;
    },
    setRooms: (state, action) => {
      state.rooms = action.payload;
    },
    setProperties: (state, action) => {
      state.properties = action.payload;
    },
    setStudent: (state, action) => {
      state.student = action.payload;
    },
  },
});

/**
 *
 * @param {{student: defaultState}} state
 */
export const getIssues = ({ student: state }) => state.issues;

/**
 *
 * @param {{student: defaultState}} state
 */
export const getReceipts = ({ student: state }) => state.receipts;

/**
 *
 *
 * @param {{student: defaultState}} state
 */
export const getRoomDetails = ({ student: state }) => state.roomDetails;

/**
 *
 *
 * @param {{student: defaultState}} state
 */
export const getCurrentFile = ({ student: state }) => state.currentFile;

/**
 *
 * @param {{student: defaultState}} state
 */
export const getPropertyDetails = ({ student: state }) => state.propertyDetails;

/**
 *
 * @param {{student: defaultState}} state
 */
export const getProperties = ({ student: state }) => state.properties;

/**
 *
 * @param {{student: defaultState}} state
 */
export const getRooms = ({ student: state }) => state.rooms;

/**
 *
 * @param {{student: defaultState}} state
 */
export const getStudent = ({ student: state }) => state.student;

export const {
  setIssues,
  setPropertyDetails,
  setRoomDetails,
  setProperties,
  setCurrentFile,
  setRooms,
  setReceipt,
  setStudent,
} = studentSlice.actions;

export default studentSlice.reducer;
