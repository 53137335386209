import React from "react";
import { useSelector } from "react-redux";
import { getCurrentPage } from "../../globals/application";
import AdminDashbard from "./components/AdminDashbard";
import Applications from "./components/Applications";
import EditBill from "./components/EditBill";
import Header from "./components/Header";
import Receipts from "./components/Receipts";
import ReportedIssues from "./components/ReportedIssues";
import Sidebar from "./components/Sidebar";
import Upload from "./components/Upload";

export default function Admin() {
  const currentPage = useSelector(getCurrentPage);
  return (
    <div id="wrapper">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <Header />
        <div className="container-fluid">
          {currentPage === "application" ? (
            <Applications />
          ) : currentPage === "upload" ? (
            <Upload />
          ) : currentPage === "dashboard" ? (
            <AdminDashbard />
          ) : currentPage === "issues" ? (
            <ReportedIssues />
          ) : currentPage === "receipts" ? (
            <Receipts />
          ) : currentPage === "upload-edit" ? (
            <EditBill />
          ) : (
            <AdminDashbard />
          )}
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
}
