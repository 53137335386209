import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProperties } from "../../../globals/student";
import { Formik, Form } from "formik";
import {
  initalValues,
  internetDueDateList,
  internetUploadSchema,
  jpsDueDate,
  nwcDueDate,
  uploadSchema,
} from "../../constants";
import { Button, FormFeedback, Input } from "reactstrap";
import { useState } from "react";
import AdminNetworkLayer from "../../../helpers/admin";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { setLoading, setLoadingMessage } from "../../../globals/application";

const mySwal = withReactContent(Swal);

export default function Upload() {
  const dispatch = useDispatch();
  const properties = useSelector(getProperties);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [localType, setLocalType] = useState("");

  const handleFormSubmit = async (values, { resetForm, setFieldValue }) => {
    dispatch(setLoading(true));
    dispatch(setLoadingMessage("Please wait while we submit the bill"));
    const params = new URLSearchParams();

    params.append("account_number", values.accountNumber);
    params.append("account_name", values.accountName);
    params.append("bill_name", values.billType);
    params.append("meter_number", values.meterNumber);
    params.append("balance_due", values.balanceDue);
    params.append("bill_dueDate", values.dueDate);
    params.append("bill_file", file);
    params.append("cycle_from", values.cycleFrom);
    params.append("cycle_to", values.cycleTo);
    params.append("propertyPropertyId", values.propertyId);

    let result = await AdminNetworkLayer.submitBill(params);
    if (result.success) {
      mySwal
        .fire({
          title: "Bill Submit Successfully",
          icon: "success",
          text: result.message,
        })
        .then((modal) => {
          if (modal.isConfirmed) {
            dispatch(setLoading(true));
            dispatch(
              setLoadingMessage("We are clearing the fields htmlFor you")
            );
            setFieldValue("balanceDue", "");
            setFieldValue("cycleFrom", "");
            setFieldValue("cycleTo", "");
            dispatch(setLoading(false));
          }
        });
      dispatch(setLoading(false));
    } else {
      mySwal.fire({
        title: "Bill not submitted",
        icon: "error",
        text: result.message,
      });
      dispatch(setLoading(false));
    }
  };

  const getBlob = (file) => {
    setFileName(file.name);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFile(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  return (
    <Formik
      initialValues={initalValues}
      validationSchema={
        localType === "Internet" ? internetUploadSchema : uploadSchema
      }
      onSubmit={handleFormSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <h1 className="h3 mb-4 text-gray-800">Upload Documents</h1>
          <label htmlFor="property" className="mt-2">
            Select Property
          </label>
          <select
            className="form-control mb-3"
            name="property"
            style={{ height: 50 }}
            id="property"
            onChange={(e) => {
              handleChange("propertyId")(e.target.value);
              if (values.propertyId !== "") {
                handleChange("accountNumber")(
                  properties[parseInt(e.target.value) - 1][
                    values.billType === "NWC"
                      ? "property_nwc_account"
                      : values.billType === "JPS"
                      ? "property_jps_account"
                      : "internet_account"
                  ]
                );
                handleChange("accountName")(
                  properties[parseInt(e.target.value) - 1][
                    values.billType === "NWC"
                      ? "nwc_account_name"
                      : "jps_account_name"
                  ]
                );

                handleChange("dueDate")(
                  values.billType === "NWC"
                    ? nwcDueDate
                    : values.billType === "JPS"
                    ? jpsDueDate
                    : internetDueDateList[
                        properties[e?.target?.value - 1]?.property_code
                      ].dueDate || ""
                );
              }
              handleChange("homeAddress")(
                properties.find(
                  (el) => el.property_id === parseInt(e.target.value)
                )?.property_address
              );
            }}
          >
            {properties?.map((property) => {
                return (
                  <option
                    key={property.property_id}
                    value={property.property_id}
                  >
                    {property.property_code}
                  </option>
                );
              })}
          </select>
          <label htmlFor="bill-Type">Select Bill Type</label>
          <select
            name="bill-type"
            className="form-control"
            style={{ height: 50 }}
            onChange={(e) => {
              const { value } = e.target;
              handleChange("billType")(value);
              setLocalType(value);

              handleChange("accountNumber")(
                properties[parseInt(values.propertyId) - 1][
                  e.target.value === "NWC"
                    ? "property_nwc_account"
                    : e.target.value === "JPS"
                    ? "property_jps_account"
                    : "internet_account"
                ]
              );
              handleChange("accountName")(
                properties[parseInt(values.propertyId) - 1][
                  e.target.value === "NWC"
                    ? "nwc_account_name"
                    : "jps_account_name"
                ]
              );

              handleChange("dueDate")(
                e.target.value === "NWC"
                  ? nwcDueDate
                  : e.target.value === "JPS"
                  ? jpsDueDate
                  : internetDueDateList[
                      properties[values.propertyId - 1]?.property_code
                    ].dueDate || ""
              );
            }}
          >
            {" "}
            <option value="JPS">JPS</option>
            <option value="NWC">NWC</option>
            <option value="Internet">Internet</option>
          </select>

          <div className="form-group row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label htmlFor="balance">Balance Due</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    $
                  </span>
                </div>
                {console.log(errors)}
                <Input
                  id="balance"
                  type="number"
                  className="form-control"
                  aria-label="Balance Due"
                  value={values.balanceDue}
                  aria-describedby="basic-addon1"
                  onChange={handleChange("balanceDue")}
                  onBlur={handleBlur("balanceDue")}
                  invalid={errors.balanceDue && touched.balanceDue}
                />
                {errors.balanceDue && touched.balanceDue && (
                  <FormFeedback>{errors.balanceDue}</FormFeedback>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label htmlFor="accountNum">Account Number</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    #
                  </span>
                </div>
                <Input
                  type="text"
                  disabled
                  className="form-control"
                  value={values.accountNumber}
                  invalid={errors.accountNumber && touched.accountNumber}
                />
                {errors.accountNumber && touched.accountNumber && (
                  <FormFeedback>{errors.accountNumber}</FormFeedback>
                )}
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label htmlFor="">Home Address</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fas fa-home"></i>
                  </span>
                </div>
                <Input
                  type="text"
                  className="form-control"
                  disabled
                  value={values.homeAddress}
                  invalid={errors.homeAddress && touched.homeAddress}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label htmlFor="">Due Date</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fas fa-calendar"></i>
                  </span>
                </div>

                <Input
                  type="date"
                  className="form-control"
                  aria-label="Due Date"
                  value={values.dueDate}
                  disabled
                  aria-describedby="basic-addon1"
                  onChange={handleChange("dueDate")}
                  onBlur={handleBlur("dueDate")}
                  invalid={errors.dueDate && touched.dueDate}
                />
                {errors.dueDate && touched.dueDate && (
                  <FormFeedback>{errors.dueDate}</FormFeedback>
                )}
              </div>
            </div>
          </div>
          <>
            {values.billType !== "Internet" && (
              <div className="form-group row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label htmlFor="">Billing Cycle</label>
                  <div className="input-group mb-3">
                    <div className="col-md-6">
                      <label htmlFor="">From</label>
                      <Input
                        type="date"
                        className="form-control"
                        value={values.cycleFrom}
                        aria-describedby="basic-addon1"
                        onChange={handleChange("cycleFrom")}
                        onBlur={handleBlur("cycleFrom")}
                        invalid={errors.cycleFrom && touched.cycleFrom}
                      />
                      {errors.cycleFrom && touched.cycleFrom && (
                        <FormFeedback>{errors.cycleFrom}</FormFeedback>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">To</label>
                      <Input
                        type="date"
                        className="form-control"
                        value={values.cycleTo}
                        aria-describedby="basic-addon1"
                        onChange={handleChange("cycleTo")}
                        onBlur={handleBlur("cycleTo")}
                        invalid={errors.cycleTo && touched.cycleTo}
                      />
                      {errors.cycleTo && touched.cycleTo && (
                        <FormFeedback>{errors.cycleTo}</FormFeedback>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {values.billType !== "Internet" && (
              <div className="form-group row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label htmlFor="">Account Name</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <i className="fas fa-user"></i>
                      </span>
                    </div>
                    <Input
                      type="text"
                      className="form-control"
                      value={values.accountName}
                      disabled
                      invalid={errors.accountName && touched.accountName}
                    />
                    {errors.accountName && touched.accountName && (
                      <FormFeedback>{errors.accountName}</FormFeedback>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label htmlFor="">Meter Number</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        #
                      </span>
                    </div>
                    <Input
                      type="text"
                      maxLength={8}
                      className="form-control"
                      value={values.meterNumber}
                      onChange={handleChange("meterNumber")}
                      onBlur={handleBlur("meterNumber")}
                      invalid={errors.meterNumber && touched.meterNumber}
                    />
                    {errors.meterNumber && touched.meterNumber && (
                      <FormFeedback>{errors.meterNumber}</FormFeedback>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>

          <div className="form-group row">
            <div className="col-lg-12">
              <label className="custom-file-label" htmlFor="inputGroupFile03">
                {fileName}
              </label>
              <div className="input-group mb-3">
                <div className="custom-file">
                  <Input
                    type="file"
                    className="custom-file-input"
                    id="inputGroupFile03"
                    onChange={(e) => {
                      handleChange("billFile")(e.target.files[0].name);
                      getBlob(e.target.files[0]);
                    }}
                    onClick={(e) => (e.target.value = null)}
                    onBlur={handleBlur("billFile")}
                    invalid={errors.billFile && touched.billFile}
                  />
                  {errors.billFile && touched.billFile && (
                    <FormFeedback>{errors.billFile}</FormFeedback>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Button
              disabled={
                errors.accountName ||
                errors.accountNumber ||
                errors.balanceDue ||
                errors.billFile ||
                errors.homeAddress ||
                errors.meterNumber ||
                errors.billType ||
                errors.propertyId ||
                errors.cycleFrom ||
                errors.cycleTo ||
                errors.dueDate
              }
              type="submit"
              className="mx-0, mb-4 custom-button"
              style={{ backgroundColor: "#0075c1", color: "#fff" }}
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
