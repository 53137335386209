import React, { useState, useEffect, Suspense } from "react";
import { Route, Redirect, Switch } from "react-router";
import { getAuthState, getToken } from "../../globals/auth";
import { useSelector, useDispatch } from "react-redux";
import AdminDashbard from "../../components/Admin/";

/**
 * @param {Object} props
 * @param {*} props.match
 * @param {*} props.location
 */
export default function DashbaordProtected({ match, location }) {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const loggedIn = useSelector(getAuthState);

  /** @type {[String, React.SetStateAction<React.Dispatch<String>>]} */

  const [interaction, setInteracted] = useState(false);
  console.log(interaction);

  useEffect(() => {
    function handleInteraction() {
      setInteracted(true);
    }

    const catchRedirect = (e) => {
      e.preventDefault();
      (e || window.event).returnValue = true;
      return null;
    };

    document.addEventListener("click", handleInteraction);
    document.addEventListener("keydown", handleInteraction);
    document.addEventListener("touchstart", handleInteraction);
    if (process.env.NODE_ENV !== "development")
      window.addEventListener("beforeunload", catchRedirect);

    return () => {
      document.removeEventListener("click", handleInteraction);
      document.removeEventListener("keydown", handleInteraction);
      document.removeEventListener("touchstart", handleInteraction);
    };
  }, [dispatch]);

  return (
    <section>
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {loggedIn && (
              <Route path={`${match.path}/`} component={AdminDashbard} />
            )}

            {!loggedIn && token === "" && <Redirect to="/login" />}
          </Switch>
        </Suspense>
      </div>
    </section>
  );
}
