import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Alert, Button } from "reactstrap";
import { setLoading, setLoadingMessage } from "../../../globals/application";
import { login, setCurrentUser } from "../../../globals/auth";
import AuthNetworkLayer from "../../../helpers/auth";
import zenith_logo from "./../../../assets/img/zenith-logo.jpeg";

export default function AdminLogin() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const handleLogin = async () => {
    dispatch(setLoading(true));
    dispatch(setLoadingMessage("Logging in, Please wait"));
    const params = new URLSearchParams();
    params.append("user_id", username);
    params.append("password", password);
    let result = await AuthNetworkLayer.loginAdmin(params);

    if (result.success) {
      dispatch(setLoading(false));
      dispatch(setCurrentUser(result.student));
      dispatch(login());
      history.push("/dashboard");
    } else {
      setError(true);
      dispatch(setLoading(false));
    }
  };
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          {error && (
            <Alert color="danger mt-3" fade>
              <p className="font-weight-bold">
                Either your email or password is incorrect. Plelase contact the
                Administrator if the problem persists
              </p>
            </Alert>
          )}
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-4">
              <div className="row text-center">
                <div className="col-lg-6">
                  <img width="80%" src={zenith_logo} alt="Zenith Logo" />
                </div>
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">Admin Login</h1>
                    </div>
                    <form className="user">
                      <div className="form-group text-left">
                        <label htmlFor="username">
                          User ID <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          maxLength={10}
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div className="form-group text-left">
                        <label htmlFor="username">
                          Password <span className="text-danger">*</span>
                        </label>
                        <input
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control"
                          id="exampleInputPassword"
                        />
                      </div>
                      <div className="text-center">
                        <Button
                          type="button"
                          onClick={handleLogin}
                          disabled={username === "" || password === ""}
                          className="mt-3 custom-button text-decoration-none btn"
                          to="/dashboard"
                        >
                          Login
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
