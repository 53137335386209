import React from "react";

/**
 *
 * @param {Object} props
 * @param {String} props.modalTitle
 * @param {JSX|Element} props.modalContent
 * @param {()=>void=} props.primaryCallback
 * @param {()=>void=} props.secondaryCallback
 * @param {String} prop.primaryLabel
 * @param {String} props.secondaryLabel
 */
export default function Modal({
  modalTitle,
  modalContent,
  primaryCallback,
  secondaryCallback,
  primaryLabel,
  secondaryLabel,
}) {
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document" style={{ maxWidth: 600 }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {modalTitle}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">{modalContent}</div>
          <div className="modal-footer">
            {primaryCallback && (
              <button
                type="button"
                data-dismiss="modal"
                className="btn btn-success"
                onClick={primaryCallback ? () => primaryCallback() : () => null}
              >
                {primaryLabel}
              </button>
            )}
            {secondaryCallback && (
              <button
                type="button"
                data-dismiss="modal"
                onClick={
                  secondaryLabel ? () => secondaryCallback() : () => null
                }
                className="btn btn-primary"
              >
                {secondaryLabel}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
