// import logo from './logo.svg';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.scss";
import { Provider } from "react-redux";
import { store } from "./globals";
import AdminLogin from "./components/Admin/components/AdminLogin";
import LoadSpinner from "./components/misc/LoadSpinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AdminProtected from "./pages/AdminProtected";
// let persistor = persistStore(store);
function App() {
  return (
    <Provider store={store}>
      <LoadSpinner />
      <Router>
        <div>
          <div>
            <Switch>
              <Redirect exact from="/" to="/login" />
              <Route path="/login" component={AdminLogin} />
              <Route path="/dashboard" component={AdminProtected} />
            </Switch>
            <ToastContainer />
          </div>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
