import axios from "../axios";
// import axios from "axios";
export default class AdminNetworkLayer {
  /**
   * @param  {Object} data
   * @param  {String} token
   */
  static async getStudents(data, token) {
    const endpoint = "admin/get-students";

    let response = await axios.get(endpoint, data);

    return response.data;
  }

  static async submitBill(data, token) {
    const endpoint = "admin/submit-bill";

    let response = await axios.post(endpoint, data);

    return response.data;
  }

  static async sendEmail(data, token) {
    const endpoint = "email/student";

    let response = await axios.post(endpoint, data);

    return response.data;
  }

  static async getAllIssues(data, token) {
    const endpoint = "admin/get-issues";

    let response = await axios.get(endpoint, data);

    return response.data;
  }
  static async getAllBills(data, token) {
    const endpoint = "admin/get-bills";

    let response = await axios.get(endpoint, data);

    return response.data;
  }

  static async getAllReceipts(data, token) {
    const endpoint = "admin/get-receipts";

    let response = await axios.get(endpoint, data);

    return response.data;
  }

  static async updateStatus(data, token) {
    const endpoint = "admin/change-status";

    let response = await axios.post(endpoint, data);

    return response.data;
  }

  static async updateBill(data, token) {
    const endpoint = "admin/update-bill";

    let response = await axios.post(endpoint, data);

    return response.data;
  }

  static async getFile(data, token) {
    const endpoint = "admin/get-receipt-file";

    let response = await axios.post(endpoint, data);

    return response.data;
  }
}
