import axios from "../axios";
// import axios from "axios";
export default class PropertyNetworkLayer {
  static async getProperties() {
    const endpoint = "api/properties/get-properties";

    let response = await axios.get(endpoint);

    return response.data;
  }

  /**
   *
   * @param {Object} data
   * @param {String} token
   */
  static async getRooms(data, token) {
    const endpoint = "api/properties/get-rooms";

    let response = await axios.post(endpoint, data);

    return response.data;
  }
}
